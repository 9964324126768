@import '../../../theme/main.scss';

.instructions {
    z-index: $homeZ;
    width: 100%;
    box-sizing: border-box;
    padding: $DEFAULT_PADDING / 2;
    padding-top: 26px;
    position: relative;

    @media #{$defaultBreakpoint} {
        height: 100%;
        display: grid;
        grid-template-rows: auto;
        align-items: end;
        grid-template-columns: repeat(4, 1fr);
        padding: $DEFAULT_PADDING;
        grid-gap: 30px;
    }
}

.instructionBox {
    margin-bottom: 28px;
    
    @media #{$defaultBreakpoint} {
        margin-bottom: 0px;
        height: 50%;
    }

    .index, .title {
        font-weight: $regular;
        color: $black;
        font-size: 32px;
        height: 48px;
    }

    .title {
        font-family: $mainFontLining;
        margin-bottom: 16px;
    }

    .description {
        margin-top: 16px;
        font-size: 24px;
        line-height: 24px;
        font-weight: $light;
        color: $grey;
    }

    &.createPackBox {
        align-self: center;
        height: auto;

        @media #{$defaultBreakpoint} {
            height: 50%;
            align-self: end;
        }
    }
}
