:export {
  mainFont: Favorit;
  mainFontLining: FavoritLining;
}

:export {
  iphoneWidth: 414;
  ipadWidth: 1024;
  iphone: "(max-width: 414px)";
  ipad: "(min-width: 415px) and (max-width: 1024px)";
  desktop: "(min-width: 1025px)";
  ipadOrDesktop: "(min-width: 415px)";
  retina: "(min-resolution: 2dppx)";
}

:export {
  iphoneWidth: 414;
  ipadWidth: 1024;
  iphone: "(max-width: 414px)";
  ipad: "(min-width: 415px) and (max-width: 1024px)";
  desktop: "(min-width: 1025px)";
  ipadOrDesktop: "(min-width: 415px)";
  retina: "(min-resolution: 2dppx)";
}

.instructions {
  z-index: 10;
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
  padding-top: 26px;
  position: relative;
}

@media (min-width: 1025px) {
  .instructions {
    height: 100%;
    display: grid;
    grid-template-rows: auto;
    align-items: end;
    grid-template-columns: repeat(4, 1fr);
    padding: 32px;
    grid-gap: 30px;
  }
}

.instructionBox {
  margin-bottom: 28px;
}

@media (min-width: 1025px) {
  .instructionBox {
    margin-bottom: 0px;
    height: 50%;
  }
}

.instructionBox .index,
.instructionBox .title {
  font-weight: 400;
  color: #000;
  font-size: 32px;
  height: 48px;
}

.instructionBox .title {
  font-family: "FavoritLining", sans-serif;
  margin-bottom: 16px;
}

.instructionBox .description {
  margin-top: 16px;
  font-size: 24px;
  line-height: 24px;
  font-weight: 300;
  color: #b9b9b9;
}

.instructionBox.createPackBox {
  align-self: center;
  height: auto;
}

@media (min-width: 1025px) {
  .instructionBox.createPackBox {
    height: 50%;
    align-self: end;
  }
}

