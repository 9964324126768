:export {
  mainFont: Favorit;
  mainFontLining: FavoritLining;
}

:export {
  iphoneWidth: 414;
  ipadWidth: 1024;
  iphone: "(max-width: 414px)";
  ipad: "(min-width: 415px) and (max-width: 1024px)";
  desktop: "(min-width: 1025px)";
  ipadOrDesktop: "(min-width: 415px)";
  retina: "(min-resolution: 2dppx)";
}

:export {
  iphoneWidth: 414;
  ipadWidth: 1024;
  iphone: "(max-width: 414px)";
  ipad: "(min-width: 415px) and (max-width: 1024px)";
  desktop: "(min-width: 1025px)";
  ipadOrDesktop: "(min-width: 415px)";
  retina: "(min-resolution: 2dppx)";
}

.title {
  box-sizing: border-box;
  padding-bottom: 24px;
  font-size: 24px;
  font-weight: 700;
  font-family: "FavoritLining", sans-serif;
}

.content {
  border-top: none;
  box-sizing: border-box;
  font-size: 24px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.content p {
  margin: 0px;
}

.content input {
  margin: 7px 0 16px 0;
}

