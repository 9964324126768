@import '../../../theme/main.scss';

.title {
    box-sizing: border-box;
    padding-bottom: 24px;
    font-size: 24px;
    font-weight: $bold;
    font-family: $mainFontLining;
}

.content {
    border-top: none;
    box-sizing: border-box;
    font-size: 24px;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    p {
        margin: 0px;
    }

    input {
        margin: 7px 0 16px 0;
    }
}
